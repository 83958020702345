import './App.css';
import {useState} from "react";

const url = "https://serverless-api.oracles.workers.dev";
const getImages = async query => {
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({query}),
    headers: { "Content-type": "application/json"}
  });

  return response.json();
}

function App() {

  const [query, setQuery] = useState("");
  const [images, setImages] = useState([]);

  const search = async () => {
    setImages(await getImages(query))
  }

  return (
    <div className="App">
      <div className="form">
        <input id="query" type="text" onChange={(e) => setQuery(e.target.value)} placeholder="Search query"/>
        <button onClick={search}>Search</button>
      </div>
      {images.map((image) => 
        <a key={image.id} href={image.link} target="_blank">
          <img src={image.image}/>
        </a>
      )}
    </div>
  );
}

export default App;
